import React from 'react'
import AboutMe from './aboutMe'

class HomeIndex extends React.Component {

    render() {

        return (
            <AboutMe/>
        )
    }
}

export default HomeIndex